import React from 'react'


const Footer = () => {
  return (
    <footer className="bg-dark text-white py-5">
      <div className="container">
        <div className="d-flex justify-content-center">
          <a
            href="https://www.facebook.com/samasoftechnologies"
            rel={"noreferrer"}
            target="_blank"
          >
            <i className="fab fa-facebook fa-2x mx-3 text-primary"></i>
          </a>
          <a
            href="https://www.instagram.com/opencastgh"
            rel={"noreferrer"}
            target="_blank"
          >
            <i className="fab fa-instagram fa-2x mx-3 text-danger"></i>
          </a>
          <a
            href="https://twitter.com/SAMZY90234896"
            rel={"noreferrer"}
            target="_blank"
          >
            <i className="fab fa-twitter fa-2x mx-3 text-info"></i>
          </a>
        </div>
        <p className="text-center my-3">
          Copyright &copy; Samasoft Technologies 2023 - All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
