import React from "react";

const Card = ({ value, title, svg }) => {
  return (
    <div className="col-xl-4 col-sm-6 col-12">
      <div className="card board1 fill padding-card">
        <div className="card-body">
          <div className="dash-widget-header">
            <div>
              <h3 className="card_widget_header">{value}</h3>
              <h6 className="text-muted">{title}</h6>
            </div>
            <div className="ml-auto mt-md-3 mt-lg-0">
              <span className="opacity-7 text-muted">
                {svg}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
