import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";

export function SnackBar({ show = false, text }) {
  const [isOpen, setIsOpen] = useState(show);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => setIsOpen(false), 3000);
      const interval = setInterval(() => setProgress((prev) => prev + 1), 1000);
      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    } else {
      setProgress(0);
    }
  }, [isOpen]);

  //   const handleClick = () => {
  //     setIsOpen(!isOpen);
  //   };

  return (
    <div>
      {/* <button onClick={handleClick}>Show snackbar</button> */}
      {isOpen && (
        <div className={`snackbar ${isOpen ? "show" : ""}`}>
          <i className="fa fa-check-circle me-2"></i>
          <span>{text}</span>
          <div
            className="progress-bar icon-success"
            style={{ width: `${(progress / 30) * 100}%` }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default SnackBar;
